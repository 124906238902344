import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureCard = makeShortcode("FeatureCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <FeatureCard subTitle="Frontend development" title="React framework" actionIcon="arrowRight" href="https://www.creatiweb.ca" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.34722222222222%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABOUlEQVQoz4VSa3OCQAy8FxyPexR5VKp+cEZH4f//v20uiK2ttczsJCy5zeaCGBqPvmsR4xtiCAiEGCOjKAqUZcl54lNeVRWcc0+RagQ9+At932McRxwOBy7ebDbI8xyvzgilJJRSkFLdooTWGvv9HtM0oWkaZFnG3PF4xOVyYZfpcKr9CfGLoMKu67Db7R46W2uZTw2GYUBd19xkNfGnYCpo25ZjEkoTrPw4btnh6XTC+XzmmO75u1uRugjBynfSGEOFJY+6OmDndKfTNLPo9XrFPM+8qAdBRYJaCWRK3sdTkt6NRk4wWtF3ckh8VVg0McD7ZaveeyyGHhYjkGsJa74Eg1X48AZbpzHUGiPl784Qp1Fq8XrLz8iCDrWlhMsEDLnNaQKrlviPGERYf+ZbXOFX+AXuFkN4jU8mINpQUpoLIwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shopify online store",
          "title": "Shopify online store",
          "src": "/static/3966aa1cd49d625e35dc3bfd4f0f6f8b/3cbba/creatiwebca.png",
          "srcSet": ["/static/3966aa1cd49d625e35dc3bfd4f0f6f8b/7fc1e/creatiwebca.png 288w", "/static/3966aa1cd49d625e35dc3bfd4f0f6f8b/a5df1/creatiwebca.png 576w", "/static/3966aa1cd49d625e35dc3bfd4f0f6f8b/3cbba/creatiwebca.png 1152w", "/static/3966aa1cd49d625e35dc3bfd4f0f6f8b/0b124/creatiwebca.png 1728w", "/static/3966aa1cd49d625e35dc3bfd4f0f6f8b/4ea3d/creatiwebca.png 1918w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <FeatureCard subTitle="Design and setup" title="Shopify online store" actionIcon="arrowRight" href="https://www.ninaconceptcadeau.com" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50.34722222222222%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwUlEQVQozx2QbU9aBwBG74/YlyVNkyZLusRlTb+0nY2drVZxolwQxCkCXkBEhkCpiq1QbSPS+kIFZVap4gVFELGgBa32RZf0JftRpzf9A+c5zxHi017+y6U5nxmnYtVyYDOwb9Kwr79LTrzFQU8DtVEz7yNeSm4dBbsKufsO+0N6auMSJ0/9HE442Bu1UgzaEVafernYWuVdcIiSWcOeRcduTxs5XQMpsYHioMh59CEfZny8feSg5O9jx20kb9dQHFBRGXOQ8xhYkzpYd/UibM5NcrYwSdXTS9EikjN1st6nYl2ByZYOKkEXp2EvJ6ER3oa8FAM2Nk1tbOjuIPe1Uhg2KmYOUk49Lw1NCKfZDeSwk5JNw6HUxa5VQ7JfQ0yvYsdpoDo2RPmBg6MxF28U+CtJS8rYQkLX9AOcH+6mMGojG7CwqLwSPq7EycfniDm1LHY18ri9EeON6wyomig99ip2fo5DDygEh0n4JGSvxKrVwKq+iW2zmqyzi4yvH/mhlWmTCuHrdob/83ly8Sg7U2biUjPGv/5A/PMmM4YWVsRGPj3xUAj/Q1i8x7POu0TF+2RMajb72khLIlsuI1sBM4MdtxG+ZWS+plNcxBfIPnFz4GmlvORjO2Jj1tjIimQkFxgiE3CRHnGQdPYT61WT6m1nq19N2qLltd3AC2Wg+WYdQm05xkVyiY8zk5RHlUY2FRvDbeyH3JzE5jhOJqguv6QyH2Fv+hG7EwFkv4v5v9VEtc3M9XQy0nqb7vrf+a3uCsLalJ/UhIdKaIyaYrCmLMojdo7mF6nEFjhafEEtEaOajFOanyUXDio9A0TM3bTU11F/7SrXr17m119+5vKlnxA+HxYpbyxTikc4XZilOh2m8nyWs38TvH+1zLmS48veLl/KJS6Kec7SrzlWjA+XomSTkzyfcjM40EW76h5T4z6+A1IM5/wHmepfAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shopify online store",
          "title": "Shopify online store",
          "src": "/static/f9e9ced82a41373ad87057678eb73339/3cbba/shopify-online-store.png",
          "srcSet": ["/static/f9e9ced82a41373ad87057678eb73339/7fc1e/shopify-online-store.png 288w", "/static/f9e9ced82a41373ad87057678eb73339/a5df1/shopify-online-store.png 576w", "/static/f9e9ced82a41373ad87057678eb73339/3cbba/shopify-online-store.png 1152w", "/static/f9e9ced82a41373ad87057678eb73339/0b124/shopify-online-store.png 1728w", "/static/f9e9ced82a41373ad87057678eb73339/4ea3d/shopify-online-store.png 1918w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      